<template>
  <section class="confirm-header">
    <div class="container">
      <div class="flex__between flex__reverse">
        <router-link :to="pastOrdersRoute" class="breadcrumb">
          Back to Orders
          <font-awesome-icon icon="arrow-right" size="1x" class="ml-10" />
        </router-link>
        <router-link v-if="canEdit" :to="link" class="breadcrumb">
          <font-awesome-icon icon="arrow-left" size="1x" class="mr-10" />Back to Products
        </router-link>
      </div>
      <h1 class="text__center padding__sm">{{ heading }}</h1>
      <div class="flex__between flex__reverse">
        <button
          v-if="!isStarted"
          class="btn btn__sm btn__green"
          @click="emitToggleStatus"
        >Status: {{ active.status }}</button>
        <button
          v-if="showTicketing"
          class="btn btn__sm btn__frost"
          @click="emitToggleTicketing"
        >Ticketing</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["active", "user"],
  data() {
    return {
      access: ["admin", "super-admin"]
    };
  },
  computed: {
    route() {
      return this.$route.fullPath;
    },
    pastOrdersRoute() {
      return this.access.includes(this.user.permission)
        ? "/admin/past-orders"
        : "/my-account/past-orders";
    },
    link() {
      return this.route.includes("/new-order")
        ? `/new-order/${this.active._id}/products`
        : `/past-order/${this.active._id}/products`;
    },
    heading() {
      return this.route.includes("/new-order")
        ? "New Order: Confirm"
        : "Past Order: Confirm";
    },
    isStarted() {
      return this.active.status === "Started";
    },
    canEdit() {
      const status = ["Started"];
      return (
        this.access.includes(this.user.permission) ||
        status.includes(this.active.status)
      );
    },
    showTicketing() {
      return (
        this.access.includes(this.user.permission) && this.active.hfTicketId
      );
    }
  },
  methods: {
    emitToggleExiting() {
      this.$emit("toggleExiting", true);
    },
    emitToggleTicketing() {
      if (!this.canEdit) return;
      this.$emit("toggleTicketing", true);
    },
    emitToggleStatus() {
      if (!this.canEdit) return;
      this.$emit("toggleStatus");
    }
  }
};
</script>

<style scoped lang="scss">
</style>