<template>
  <section class="margin__md">
    <section class="container">
      <div v-if="isStarted">
        <div class="tile-heading">
          <h2>Note</h2>
          <hr />
        </div>
        <p>
          This order has not been submitted for processing.
          <br />Please look over the following details to confirm everything is correct and click "Confirm and Submit" when ready.
        </p>
      </div>
      <div v-else>
        <div class="tile-heading">
          <h2>Notes</h2>
          <hr />
        </div>
        <p>The WOW department has received this order and is currently processing it. If you need to request an edit to this order or would like some information about it please respond to the email which was sent to you when the order was originally submitted.</p>
        <p>To check on the status of a particular product click on the product's 'View' button above. You may view its status, current customizations, and tracking number.</p>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  props: ["active"],
  computed: {
    isStarted() {
      return this.active.status === "Started";
    }
  }
};
</script>