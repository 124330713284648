<template>
  <li class="confirm-products--item">
    <div class="confirm-products--list-item pos__rel tile">
      <ProductStatus :status="item.status" />
      <div class="confirm-products__grid">
        <img class="confirm--img" :src="productImg" />
        <p class="confirm--name text__bold text__md p-tb-sm">{{ item.productName }}</p>
        <div class="cust--list-container">
          <!-- <div class="text__bold flex__between">
            <span>Customizations</span>
            <hr class="ml-10" />
          </div>-->
          <!-- <simplebar class="cust--overflow" data-simplebar-auto-hide="false"> -->
            <div
              v-if="!item.customizations || item.customizations.length <= 0"
              class="flex__col padding__sm"
            >
              <p class="text__bold text__center">No customizations for this product</p>
            </div>
            <ul v-else class="cust--list">
              <li v-for="cust in item.customizations" :key="cust._id">
                <span class="text__bold">{{ cust.name }}:</span>
                <span class="ml-10 pre-wrap">{{ cust.option }}</span>
              </li>
            </ul>
          <!-- </simplebar> -->
        </div>
        <p>
          <span class="text__bold mr-10">Qty:</span>
          <span>{{ item.quantity }}</span>
        </p>
        <p>
          <span class="text__bold mr-10">Total Cost:</span>
          <span
            class="text__bold text__pine text__md"
          >${{ (item.quantity * item.loCost).toFixed(2) }}</span>
        </p>
        <p v-if="item.trackingNum">
          <span class="text__bold mr-10">Tracking</span>
          <span>{{ item.trackingNum }}</span>
        </p>
      </div>
    </div>
    <div v-if="!deleting && canEdit" class="dual--buttons">
      <router-link :to="link" class="btn__green flex__center link__none">
        <font-awesome-icon icon="edit" size="1x" class="mr-10" />Edit
      </router-link>
      <div class="btn__red flex__center" @click="toggleDelete">
        <font-awesome-icon icon="times" size="1x" class="mr-10" />Delete
      </div>
    </div>
    <div v-if="deleting && canEdit" class="dual--buttons">
      <div class="btn__green flex__center" @click="deleteOrderItem">Confirm Delete</div>
      <div class="btn__red flex__center" @click="toggleDelete">Cancel</div>
    </div>
    <router-link v-if="!canEdit" :to="link" class="btn__green flex__center link__none">
      <font-awesome-icon icon="edit" size="1x" class="mr-10" />View
    </router-link>
  </li>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProductStatus from "./ProductStatus";
export default {
  props: ["item", "order", "canEdit"],
  components: {
    ProductStatus
  },
  data() {
    return {
      deleting: false
    };
  },
  computed: {
    ...mapGetters(["getProducts", "getUserDetails"]),
    productImg() {
      return this.getProducts.filter(
        product => product._id === this.item.product
      )[0].images[0];
    },
    route() {
      return this.$route.fullPath;
    },
    link() {
      return this.route.includes("/new-order")
        ? `/new-order/${this.order._id}/products/${this.item.product}?item=${this.item._id}`
        : `/past-order/${this.order._id}/products/${this.item.product}?item=${this.item._id}`;
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startDeleteOrderItem"]),
    toggleDelete() {
      return (this.deleting = !this.deleting);
    },
    async deleteOrderItem() {
      try {
        this.startSetIsSubmitting({ bool: true, heading: "Deleting" });
        await this.startDeleteOrderItem(this.item);
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.confirm-products--item {
  @include grid($cols: 1fr, $rows: auto 35px);
}
.confirm-products--list-item {
  background-color: lighten($frost, 5%);
}
.confirm-products__grid {
  padding: 8px;
  @include grid($cols: 1fr, $row-gap: 5px);
}
.confirm--img {
  width: 175px;
  height: 175px;
  margin: auto;
}
.confirm--name {
  line-height: 1.2rem;
}
.cust--overflow {
  height: 100px;
}
.cust--list {
  @include grid($cols: 1fr, $row-gap: 8px);
  padding: 5px 0px;
  li {
    @include flex($just: space-between, $align: flex-start, $wrap: wrap);
    span:nth-child(2) {
      text-align: right;
    }
    font-size: 0.9rem;
  }
}
.dual--buttons {
  height: 35px;
}
</style>