<template>
  <section class="confirm-footer">
    <div class="container">
      <div class="confirm-footer--content">
        <div class="tile-heading">
          <h2>Submit Your Order</h2>
          <hr />
        </div>
        <p class="form-error--message" v-if="orderItemsError">{{ orderItemsError }}</p>
        <p
          class="form-error--message"
          v-if="formissue"
        >There is an issue with the final details above.</p>
        <div class="confirm-footer--content__grid padding__sm">
          <button class="btn btn__red text__md" @click="emitToggleExiting">Exit & Save</button>
          <button class="btn btn__green text__center" @click="emitSubmit">
            <span class="text__white text__md text__bold">
              <font-awesome-icon icon="shopping-cart" size="lg" class="mr-10" />
              ${{ balanceDue.toFixed(2) }}
            </span>
            <span class="text__white text__md">Confirm and Submit</span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["active", "formissue", "orderItemsError", "balanceDue"],
  data() {
    return {
      access: ["admin", "super-admin"],
      form: {
        _id: this.active ? this.active._id : null
      }
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateOrder"]),
    emitSubmit() {
      this.$emit("submit", true);
    },
    emitToggleExiting() {
      this.$emit("toggleExiting", true);
    }
  }
};
</script>

<style scoped lang="scss">
.confirm-footer--content {
  @include grid($cols: 1fr, $row-gap: 15px, $col-gap: 15px);
  padding: 25px 0;
}
.confirm-footer--content__grid {
  @include grid($cols: 1fr, $row-gap: 20px, $col-gap: 50px);
  .btn__green {
    @include grid($cols: 1fr, $row-gap: 3px);
  }
}
.icon-container {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: #fff;
}
.icon {
  margin-top: 4px;
  color: $green;
  font-size: 1.85rem;
  transition: all 0.15s ease-in-out;
}
.icon-filled {
  color: $maroon;
}
.icon:hover {
  color: $maroon;
  animation: 0.5s ease-in-out infinite pulse;
}
@keyframes pulse {
  0% {
    font-size: 1.85rem;
  }
  50% {
    font-size: 2.25rem;
  }
  100% {
    font-size: 1.85rem;
  }
}
@media (min-width: $md) {
  .confirm-footer--content__grid {
    grid-template-columns: 1fr 1fr;
  }
}
</style>