<template>
  <section class="confirm-finals">
    <div class="container">
      <section class="margin__md">
        <div class="tile-heading">
          <h2>Final Details</h2>
          <hr />
        </div>
        <div class="tile">
          <div class="confirm-finals__grid">
            <div v-if="canEdit && isPastOrder" class="flex__between">
              <p>See below section if editing Bill To</p>
              <button
                class="btn btn__sm btn__red"
                @click="toggleEditing"
              >{{ editing ? "Cancel" : "Edit" }}</button>
            </div>
            <label>
              <span class="text__bold">Requester Name</span>
              <span>{{ active.requesterName }}</span>
            </label>
            <label>
              <span class="text__bold">Requester Email</span>
              <a
                :href="`mailto: ${active.requesterEmail}`"
                rel="nofollow"
                class="link__none"
                name="email requester"
              >{{ active.requesterEmail }}</a>
            </label>
            <label>
              <span class="text__bold">Requester Second Email</span>
              <a
                :href="`mailto: ${active.requester2Email}`"
                rel="nofollow"
                class="link__none"
                name="email requester"
              >{{ active.requester2Email }}</a>
            </label>
            <label v-if="showThankYouNote" :class="[errors.thankYouNote ? 'form--error' : null]">
              <span class="text__bold">Thank You Card</span>
              <span
                v-if="!canEdit || !editing"
              >{{ active.thankYouNote ? active.thankYouNote : "nothing provided" }}</span>
              <div
                v-else
                class="form--comments"
                :class="[errors.thankYouNote ? 'form--error' : null]"
              >
                <textarea
                  v-model="form.thankYouNote"
                  placeholder="Dear Mr. ..."
                  rows="3"
                  maxlength="500"
                />
                <canned @setMessage="setThankYou" />
              </div>
            </label>
            <form-error :message="errors.thankYouNote" />
            <div class="grid__two">
              <div>
                <label :class="[errors.billingMethod ? 'form--error': null]">
                  <span class="text__bold">Billing Method*</span>
                  <span v-if="!canEdit || !editing">{{ form.billingMethod }}</span>
                  <select v-else v-model="form.billingMethod">
                    <option value></option>
                    <option value="Payroll">Payroll</option>
                    <option value="Marketing Budget">Marketing Budget (if available)</option>
                  </select>
                </label>
                <form-error :message="errors.billingMethod" />
              </div>
              <div>
                <label
                  class="pos__rel billing"
                  :class="[errors.billingUser ? 'form--error' : null]"
                >
                  <span class="text__bold">Bill To*</span>
                  <span
                    v-if="!editing"
                  >{{ selectedBillingUser ? selectedBillingUser.displayName : "none selected" }}</span>
                  <div v-else class="selected-user flex__between" @click="toggleUsers">
                    <span>{{ selectedBillingUser ? selectedBillingUser.displayName : "none" }}</span>
                    <font-awesome-icon v-if="!usersToggled" icon="caret-down" size="sm" />
                    <font-awesome-icon v-if="usersToggled" icon="caret-up" size="sm" />
                  </div>
                  <div v-if="usersToggled && canEdit & editing" class="billing-search">
                    <input type="text" v-model="search" placeholder="search users" />
                    <div v-if="!users" class="flex__center">
                      <p class="text__bold text__center">Loading Users</p>
                    </div>
                    <div v-else class="billto-list--container">
                      <simplebar class="minor--overflow" data-simplebar-auto-hide="false">
                        <div class="billto-list">
                          <label
                            class="billto-list--item"
                            v-for="user in filteredBillingUsers"
                            :key="user._id"
                          >
                            <input
                              type="radio"
                              v-model="form.billingUser"
                              :value="user._id"
                              @change="toggleUsers"
                            />
                            <span>{{ user.email }}</span>
                          </label>
                        </div>
                      </simplebar>
                    </div>
                  </div>
                </label>
                <form-error :message="errors.billingUser" />
              </div>
            </div>
            <label>
              <span>
                <span class="mr-10 text__bold">Use Award Balance</span>
                <span
                  v-if="getAwardBal !== null && canEdit && editing"
                  class="text__sm"
                >${{ getAwardBal.toFixed(2) }} available</span>
              </span>
              <span v-if="!canEdit || !editing">{{ active.useAwardBalance ? "Yes" : "No" }}</span>
              <select v-else v-model="form.useAwardBalance">
                <option :value="true">Yes</option>
                <option :value="false">No</option>
              </select>
            </label>
            <!-- <label>
              <span class="text__bold">Requested Delivery Date</span>
              <span v-if="!canEdit || !editing">{{ cleanDate(active.deliveryDate) }}</span>
              <input
                v-else
                type="date"
                v-mask="'####-##-##'"
                placeholder="YYYY-MM-DD"
                v-model="form.deliveryDate"
              />
            </label>-->
            <label class="pos__rel mb-15">
              <span class="text__bold">Order Comments</span>
              <span
                v-if="!canEdit || !editing"
                class="pre-wrap"
              >{{ active.comments ? active.comments : "No comments provided" }}</span>
              <div v-else class="form--comments">
                <textarea v-model="form.comments" rows="4" />
                <canned @setMessage="setComments" />
              </div>
            </label>
            <div v-if="canEdit && isPastOrder && editing" class="flex__between">
              <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
              <button class="btn btn__sm btn__green" @click="update">Done</button>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer
      v-if="canEdit && !isPastOrder"
      :active="active"
      :formissue="!isValidated"
      :orderItemsError="errors.orderItemsError"
      :balanceDue="balanceDueCalc"
      @submit="submit"
      @toggleExiting="toggleExiting"
    />
    <exit v-if="exitToggled">
      <ExitSave @submit="exitSave" @toggleExiting="toggleExiting" />
    </exit>
  </section>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { createSuccess } from "../../../libs/flash-messages";
import Footer from "./Footer";
import ExitSave from "./ExitSave";
export default {
  props: ["active", "canEdit"],
  components: {
    Footer,
    ExitSave
  },
  data() {
    return {
      editing: false,
      isValidated: true,
      exitToggled: false,
      usersToggled: false,
      form: {
        _id: this.active ? this.active._id : null,
        billingMethod: this.active ? this.active.billingMethod : null,
        billingUser: this.active ? this.active.billingUser : null,
        billingName: this.active ? this.active.billingName : null,
        billingEmail: this.active ? this.active.billingEmail : null,
        thankYouNote: this.active ? this.active.thankYouNote : null,
        useAwardBalance: this.active ? this.active.useAwardBalance : false,
        awardAmountUsed: this.active ? this.active.awardAmountUsed : null,
        balanceDue: this.active ? this.active.balanceDue : null,
        deliveryDate: this.active ? this.active.deliveryDate : null,
        comments: this.active ? this.active.comments : null,
        status: this.active ? this.active.status : null,
        hfTicketId: this.active ? this.hfTicketId : null
      },
      errors: {
        billingMethod: null,
        billingUser: null,
        thankYouNote: null,
        orderItemsError: null
      },
      users: null,
      search: ""
    };
  },
  computed: {
    ...mapGetters(["getUserDetails", "getProducts"]),
    showThankYouNote() {
      return this.active
        ? this.active.orderItems.filter(item => item.isInventoried).length > 0
        : false;
    },
    filteredUsers() {
      if (!this.users) return null;
      const fullAccess = ["admin", "super-admin", "asm"];
      if (fullAccess.includes(this.getUserDetails.permission)) {
        return this.users;
      }
      return this.users.filter(item => {
        return item.delegatedUsers.includes(this.getUserDetails._id);
      });
    },
    filteredBillingUsers() {
      if (!this.users) return null;
      if (!this.search) return this.filteredUsers;
      return this.filteredUsers.filter(user => {
        return user.displayName
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    selectedBillingUser() {
      if (!this.users) return null;
      if (!this.form.billingUser) return null;
      return this.filteredUsers.filter(
        user => user._id === this.form.billingUser
      )[0];
    },
    getAwardBal() {
      if (!this.form.billingUser) return 0;
      if (!this.users) return 0;
      return this.users.filter(user => {
        return user._id === this.form.billingUser;
      })[0].awardBalance;
    },
    totalCost() {
      if (!this.active) return 0;
      if (!this.active.orderItems || this.active.orderItems.length <= 0)
        return 0;
      return this.active.orderItems
        .map(item => {
          const productCost = this.getProducts.filter(
            product => item.product === product._id
          )[0].approxValue;
          return item.quantity * productCost;
        })
        .reduce((a, b) => {
          return a + b;
        }, 0);
    },
    balanceDueCalc() {
      if (this.form.useAwardBalance && this.getAwardBal) {
        const due = this.active.totalCost - this.getAwardBal;
        return due > 0 ? due : 0;
      }
      return this.active.balanceDue;
    },
    showRespaIssue() {
      if (this.active.recipientType === "Internal Employee") return false;
      if (this.active.coRecipientName) {
        return this.totalCost > 50;
      } else {
        return this.totalCost > 25;
      }
    },
    isPastOrder() {
      return this.$route.fullPath.includes("/past-order");
    }
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "loadDelegates",
      "createOrderTicket",
      "startUpdateOrder",
      "startUpdateUser"
    ]),
    async submit() {
      this.validateForm();
      if (!this.isValidated) return;
      this.startSetIsSubmitting({ bool: true, heading: "Submitting" });
      try {
        this.form.status = "Submitted";
        this.form.billingUser = this.selectedBillingUser._id;
        this.form.billingName = this.selectedBillingUser.displayName;
        this.form.billingEmail = this.selectedBillingUser.email;
        this.form.awardAmountUsed = this.active.totalCost - this.balanceDueCalc;
        this.form.balanceDue = this.balanceDueCalc;
        const hfTicketId = await this.createOrderTicket({
          form: this.form,
          active: this.active,
          showRespaIssue: this.showRespaIssue
        });
        this.form.hfTicketId = hfTicketId;
        await this.startUpdateOrder(this.form);
        await this.updateUser();
        this.startSetIsSubmitting({ bool: false });
        createSuccess("Order was Submitted!");
        this.$router.push("/my-account/past-orders");
      } catch (err) {
        this.form.status = this.active.status;
        this.startSetIsSubmitting({ bool: false });
      }
    },
    async update() {
      this.validateForm();
      if (!this.isValidated) return;
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        this.form.billingUser = this.selectedBillingUser._id;
        this.form.billingName = this.selectedBillingUser.displayName;
        this.form.billingEmail = this.selectedBillingUser.email;
        this.form.awardAmountUsed = this.active.totalCost - this.balanceDueCalc;
        this.form.balanceDue = this.balanceDueCalc;
        await this.startUpdateOrder(this.form);
        await this.updateUser();
        this.toggleEditing();
        this.startSetIsSubmitting({ bool: false });
        createSuccess("Order updated!");
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    async updateUser() {
      const newBal =
        this.selectedBillingUser.awardBalance - this.form.totalCost;
      const form = {
        _id: this.selectedBillingUser._id,
        displayName: this.selectedBillingUser.displayName,
        awardBalance: newBal > 0 ? newBal : 0
      };
      try {
        await this.startUpdateUser(form);
        return Promise.resolve("success");
      } catch (err) {
        return Promise.reject(err);
      }
    },
    validateForm() {
      this.isValidated = true;
      this.errors.billingMethod =
        this.form.billingMethod === "" || !this.form.billingMethod
          ? "Please select a billing method"
          : null;
      this.errors.billingUser = !this.form.billingUser
        ? "Please select a person to bill to"
        : null;
      this.errors.thankYouNote =
        this.showThankYouNote && (!this.form.thankYouNote || this.form.thankYouNote.length) <= 0
          ? "Please input a thank you message"
          : null;
      this.errors.orderItemsError =
        !this.active.orderItems || this.active.orderItems.length <= 0
          ? "You must choose at least one product for this order"
          : null;
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    },
    async exitSave() {
      //save the current details above without validating
      // this will not submit the order and won't flag it as ready
      this.startSetIsSubmitting({ bool: true, heading: "Saving" });
      try {
        await this.startUpdateOrder(this.form);
        this.startSetIsSubmitting({ bool: false });
        createSuccess("Order Saved!");
        this.$router.push("/");
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    toggleExiting() {
      this.exitToggled = !this.exitToggled;
    },
    toggleEditing() {
      if (!this.canEdit) return;
      this.editing = !this.editing;
    },
    toggleUsers() {
      this.search = "";
      this.usersToggled = !this.usersToggled;
    },
    setComments(e) {
      this.form.comments = e.message;
    },
    setThankYou(e) {
      this.form.thankYouNote = e.message;
    },
    cleanDate(date) {
      if (!date) return "none selected";
      return moment(date).format("MM-DD-YYYY");
    }
  },
  async mounted() {
    try {
      this.users = await this.loadDelegates();
    } catch (err) {
      console.log(err);
    }
    if (this.$route.fullPath.includes("/new-order")) this.editing = true;
  }
};
</script>

<style scoped lang="scss">
.confirm-finals__grid {
  @include grid($cols: 1fr, $row-gap: 15px);
}
.confirm-finals__grid label {
  @include grid($cols: 1fr, $row-gap: 5px);
}
.billto-list--container {
  padding: 8px 5px;
  border-radius: 3px;
}
.minor--overflow {
  height: 175px;
}
.selected-user {
  border: 1px solid $dark-blue;
  border-radius: 3px;
  padding: 10px;
  height: 2.75rem;
}
.billing-search {
  @include pos-abs(
    $pos: absolute,
    $top: calc(100% + 15px),
    $left: null,
    $right: 0,
    $width: 100%,
    $z: 10
  );
  @include grid($rows: 40px 1fr, $row-gap: 10px);
  background-color: $frost;
  padding: 10px;
  border-radius: 5px;
  @include shadow;
  height: 250px;
}
.billto-list {
  @include grid($cols: 1fr, $row-gap: 5px, $col-gap: 10px);
}
.billto-list label {
  @include grid($cols: 1fr 7fr, $col-gap: 10px, $align: center);
  padding: 5px 8px;
  transition: background-color 0.15s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: lighten($frost, 10%);
  }
}
.grid__two > div {
  @include grid($cols: 1fr, $row-gap: 10px);
}
@media (min-width: $md) {
  .grid__two {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    align-items: start;
  }
}
</style>