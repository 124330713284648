<template>
  <section class="modal flex__center">
    <Ticketing @toggleTicketing="emitToggleTicketing" :ticketNum="ticketNum" />
  </section>
</template>

<script>
import Ticketing from "../../Ticketing";
export default {
  props: ["ticketNum"],
  components: {
    Ticketing
  },
  methods: {
    emitToggleTicketing() {
      this.$emit("toggleTicketing");
    }
  }
};
</script>

<style scoped lang="scss">
</style>