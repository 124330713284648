<template>
  <section class="margin__md">
    <div class="tile-heading">
      <h2>Products</h2>
      <hr />
    </div>
    <section class="confirm-products">
      <p class="mb-15">{{ getNotes.productNotes }}</p>
      <p v-if="showRespaIssue" class="form-error--message text__sm text__center">
        <font-awesome-icon icon="exclamation" size="lg" class="icon mr-10" />
        <span
          class="text__white"
        >This order may not be respa compliant. You may still submit but a WOW representative may require approval from your ASM.</span>
      </p>
      <div
        v-if="!activeOrder.orderItems || activeOrder.orderItems.length <= 0"
        class="padding__md flex__col"
      >
        <router-link :to="link" class="breadcrumb text__bold text__center">
          Add Products
          <font-awesome-icon icon="plus-circle" size="lg" class="ml-10" />
        </router-link>
      </div>
      <ul v-else class="confirm-products--list">
        <Product
          v-for="item in activeOrder.orderItems"
          :key="item._id"
          :item="item"
          :order="activeOrder"
          :canEdit="canEdit"
        />
      </ul>
    </section>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Product from "./Product";
export default {
  props: ["activeOrder"],
  components: {
    Product
  },
  data() {
    return {
      access: ["access", "super-admin"]
    };
  },
  computed: {
    ...mapGetters(["getNotes", "getProducts", "getUserDetails"]),
    route() {
      return this.$route.fullPath;
    },
    link() {
      return this.route.includes("/new-order")
        ? `/new-order/${this.activeOrder._id}/products`
        : `/past-order/${this.activeOrder._id}/products`;
    },
    canEdit() {
      return (
        this.access.includes(this.getUserDetails.permission) ||
        this.activeOrder.status === "Started"
      );
    },
    totalCost() {
      if (!this.activeOrder) return 0;
      if (
        !this.activeOrder.orderItems ||
        this.activeOrder.orderItems.length <= 0
      )
        return 0;
      return this.activeOrder.orderItems
        .map(item => {
          const productCost = this.getProducts.filter(
            product => item.product === product._id
          )[0].approxValue;
          return item.quantity * productCost;
        })
        .reduce((a, b) => {
          return a + b;
        }, 0);
    },
    showRespaIssue() {
      if (this.activeOrder.recipientType === "Internal Employee") return false;
      if (this.activeOrder.coRecipientName) {
        return this.totalCost > 50;
      } else {
        return this.totalCost > 25;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.confirm-products--list {
  @include grid($cols: 1fr, $row-gap: 25px, $col-gap: 25px);
}
.form-error--message {
  line-height: 1.2rem;
  font-size: 0.9rem;
  margin-bottom: 15px;
}
@media (min-width: $md) {
  .confirm-products--list {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $xl) {
  .confirm-products--list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>