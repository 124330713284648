<template>
  <section class="modal flex__center">
    <div class="modal-status tile">
      <h3 class="text__center">Edit Status</h3>
      <p
        class="text__center text__sm"
      >You cannot move the status to complete until all order-items are complete</p>
      <select v-model="form.status">
        <!-- <option value="Started">Started</option> -->
        <option
          value="Submitted"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete'"
        >Submitted</option>
        <option
          value="Ordered"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete'"
        >Ordered</option>
        <option
          value="In Progress"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete'"
        >In Progress</option>
        <option
          value="Awaiting Input"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete'"
        >Awaiting Input</option>
        <option
          value="Ready to Ship"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete'"
        >Ready to Ship</option>
        <option
          value="Sent"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete'"
        >Sent</option>
        <option
          value="Beer"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete'"
        >Beer</option>
        <option
          value="Pre-Order"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete'"
        >Pre-Order</option>
        <option
          value="Complete"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete' || !canMarkComplete"
        >Complete</option>
        <option
          value="Admin Complete"
          :disabled="active.status === 'Admin Complete' || !canMarkComplete"
        >Admin Complete</option>
      </select>
      <div class="flex__between">
        <button class="btn btn__sm btn__red" @click="emitToggleStatus">Cancel</button>
        <button class="btn btn__sm btn__green" @click="submitStatus">Done</button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["active"],
  data() {
    return {
      form: {
        _id: this.active ? this.active._id : null,
        status: this.active ? this.active.status : null
      }
    };
  },
  computed: {
    canMarkComplete() {
      //check if all orderItem statuses are "Completed"
      return this.active.orderItems
        .map(item => item.status === "Completed")
        .reduce((a, b) => a && b, true);
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateOrder"]),
    emitToggleStatus() {
      this.$emit("toggleStatus");
    },
    async submitStatus() {
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        await this.startUpdateOrder(this.form);
        this.emitToggleStatus();
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.modal-status {
  width: 90%;
  max-width: 350px;
  @include grid($cols: 1fr, $row-gap: 15px);
}
</style>