<template>
  <section class="confirm">
    <Header
      @toggleExiting="toggleExiting"
      @toggleTicketing="toggleTicketing"
      @toggleStatus="toggleStatus"
      :active="getActiveOrder"
      :user="getUserDetails"
    />
    <FinalNotes :active="getActiveOrder" />
    <section class="container">
      <Details :activeOrder="getActiveOrder" />
      <Products :activeOrder="getActiveOrder" />
    </section>
    <FinalDetails :active="getActiveOrder" :canEdit="canEdit" />
    <Reorder v-if="isPastOrder" />
    <OrderClose
      v-if="isPastOrder"
      :active="getActiveOrder"
      :canEdit="canEdit"
      @toggleStatus="toggleStatus"
    />
    <section v-if="isPastOrder" class="container">
      <div class="flex__between margin__sm">
        <router-link to="/" class="breadcrumb text__bold">
          <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />Back Home
        </router-link>
        <router-link to="/past-orders" class="breadcrumb text__bold">
          Back To Orders
          <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
        </router-link>
      </div>
    </section>
    <Delete
      v-if="getActiveOrder.status === 'Started' || getActiveOrder.status === 'Submitted' && access.includes(getUserDetails.permission)"
      :active="getActiveOrder"
      :user="getUserDetails"
    />
    <Favorite v-if="isPastOrder" :active="getActiveOrder" />
    <exit v-if="exiting">
      <ExitOrder @toggleExiting="toggleExiting" />
    </exit>
    <Ticketing
      v-if="ticketingToggled && isAdmin && getActiveOrder.hfTicketId"
      @toggleTicketing="toggleTicketing"
      :ticketNum="getActiveOrder.hfTicketId"
    />
    <Status v-if="statusToggled && isAdmin" @toggleStatus="toggleStatus" :active="getActiveOrder" />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./Header";
import Favorite from "./Favorite/Index";
import Details from "./OrderDetails/Details";
import Products from "./Products";
import FinalDetails from "./FinalDetails";
import Reorder from "./Reorder";
import OrderClose from "./OrderClose/Index";
import FinalNotes from "./FinalNotes";
import ExitOrder from "./ExitOrder";
import Ticketing from "./Ticketing";
import Status from "./Status";
import Delete from "./Delete";
export default {
  components: {
    Header,
    Favorite,
    Details,
    Products,
    FinalDetails,
    Reorder,
    OrderClose,
    FinalNotes,
    ExitOrder,
    Ticketing,
    Status,
    Delete
  },
  metaInfo: {
    title: "Order Confirm | WOW"
  },
  data() {
    return {
      exiting: false,
      ticketingToggled: false,
      statusToggled: false,
      access: ["admin", "super-admin"]
    };
  },
  computed: {
    ...mapGetters(["getActiveOrder", "getUserDetails"]),
    route() {
      return this.$route.fullPath;
    },
    isAdmin() {
      return this.access.includes(this.getUserDetails.permission);
    },
    isPastOrder() {
      return this.route.includes("/past-order");
    },
    canEdit() {
      return (
        this.access.includes(this.getUserDetails.permission) ||
        this.getActiveOrder.status === "Started"
      );
    }
  },
  methods: {
    toggleExiting() {
      this.exiting = !this.exiting;
    },
    toggleTicketing() {
      this.ticketingToggled = !this.ticketingToggled;
    },
    toggleStatus() {
      this.statusToggled = !this.statusToggled;
    }
  }
};
</script>