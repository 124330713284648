<template>
  <section class="modal flex__center">
    <section class="ticketing tile">
      <div class="tile-exit flex__center" @click="emitToggleTicketing">
        <font-awesome-icon icon="times" size="2x" />
      </div>
      <simplebar class="ticketing--overflow" data-simplebar-auto-hide="false">
        <div class="ticketing--data">
          <div class="flex__between">
            <h2>Ticketing</h2>
            <a
              v-if="data"
              :href="`https://flatbranchhomeloans.happyfox.com/staff/ticket/${ticketNum}`"
              class="link__none pointer"
              :style="`background-color: #${data.status.color}`"
              target="_blank"
            >
              <span class="text__white">Status:</span>
              <span v-if="data" class="text__bold ml-10 text__white">{{ data.status.name }}</span>
            </a>
          </div>
          <div class="ticketing--dropdown flex__between" @click="toggleNew">
            <span class="text__bold">New Response</span>
            <font-awesome-icon
              :icon="`${newToggled ? 'caret-up' : 'caret-down'}`"
              size="1x"
              class="ml-10"
            />
          </div>
          <div v-if="newToggled" class="ticketing-response">
            <label
              class="text__bold"
              for="message"
              :class="[errors.response ? 'form--error' : null]"
            >
              Message*
              <div class="form--comments">
                <textarea
                  id="message"
                  v-model="form.response"
                  rows="3"
                  placeholder="new response here"
                />
                <canned @setMessage="setResponse" />
              </div>
            </label>
            <form-error :message="errors.response" />
            <label>
              Change Status
              <select v-model="form.status">
                <option value="1">New</option>
                <option value="2">In Progress</option>
                <option value="8">Requester Action Required</option>
                <option value="3">On Hold</option>
                <option value="7">Escalated</option>
                <option value="11">Ordered</option>
                <option value="9">Project Complete</option>
                <option value="4">Closed</option>
              </select>
            </label>
            <label
              class="text__bold"
              for="message"
              :class="[errors.response ? 'form--error' : null]"
            >
              Assign Ticket
              <select v-model="form.shouldAssign">
                <option :value="true">Yes</option>
                <option :value="false">No</option>
              </select>
            </label>
            <form-error :message="errors.response" />
            <div class="flex__right">
              <button class="btn btn__sm btn__green" @click="submit">Submit</button>
            </div>
            <form-error :message="errors.hfUserId" />
          </div>
          <div class="ticketing--dropdown flex__between" @click="togglePast">
            <span class="text__bold">Past Responses</span>
            <font-awesome-icon
              :icon="`${pastToggled ? 'caret-up' : 'caret-down'}`"
              size="1x"
              class="ml-10"
            />
          </div>
          <div v-if="pastToggled" class="ticketing-messages">
            <div
              class="ticketing-messages--message tile-mini"
              v-for="msg in cleanMessages"
              :key="msg.updated"
            >
              <span class="responder">{{ msg.by }}</span>
              <span class="date">{{ cleanDate(msg.updated) }}</span>
              <p class="response">{{ msg.text }}</p>
            </div>
          </div>
        </div>
      </simplebar>
    </section>
  </section>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["ticketNum"],
  data() {
    return {
      isValidated: true,
      newToggled: false,
      pastToggled: false,
      form: {
        id: this.ticketNum ? this.ticketNum : null,
        response: "",
        status: "",
        shouldAssign: false,
        hfUserId: ""
      },
      shouldAssign: false,
      errors: {
        response: null,
        hfUserId: null
      },
      data: null,
      messages: []
    };
  },
  computed: {
    ...mapGetters(["getUserDetails"]),
    cleanMessages() {
      if (this.messages.length <= 0) return [];
      return this.messages.map(item => {
        if (item.text.includes("New WOW Request for")) {
          return {
            by: item.by,
            updated: item.updated,
            text: "New WOW Request submitted"
          };
        }
        return item;
      });
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "getTicket", "updateTicket"]),
    async submit() {
      // this.validateForm();
      // if (!this.isValidated) return;
      this.startSetIsSubmitting({ bool: true, heading: "Sending" });
      try {
        const res = await this.updateTicket(this.form);
        this.startSetIsSubmitting({ bool: false });
        this.form.response = "";
        this.setupForm(res);
        this.emitToggleTicketing();
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    // validateForm() {
    //   this.isValidated = true;
    //   this.errors.response =
    //     this.form.response === "" ? "Please enter a message" : null;
    //   this.errors.hfUserId = !this.getUserDetails.hfUserId
    //     ? "You must be a Happy Fox user to reply"
    //     : null;
    //   for (let key in this.errors) {
    //     if (this.errors[key] === null) {
    //       this.isValidated = this.isValidated && true;
    //     } else {
    //       this.isValidated = this.isValidated && false;
    //     }
    //   }
    // },
    emitToggleTicketing() {
      this.$emit("toggleTicketing");
    },
    cleanDate(date) {
      return moment(date)
        .subtract(6, "hours")
        .format("MM-DD-YYYY hh:mm a");
    },
    setupForm(res) {
      this.form.status = res.status.id;
      this.form.hfUserId = this.getUserDetails.hfUserId;
      this.data = res;
      this.messages = res.messages;
    },
    toggleNew() {
      this.newToggled = !this.newToggled;
    },
    togglePast() {
      this.pastToggled = !this.pastToggled;
    },
    setResponse(e) {
      this.form.response = e.message;
    }
  },
  async mounted() {
    try {
      this.startSetIsSubmitting({ bool: true, heading: "Loading" });
      const res = await this.getTicket(this.ticketNum);
      this.setupForm(res);
      this.startSetIsSubmitting({ bool: false });
    } catch (err) {
      this.startSetIsSubmitting({ bool: false });
    }
  }
};
</script>

<style scoped lang="scss">
.ticketing {
  width: 90%;
  max-width: 450px;
  position: relative;
}
.ticketing--overflow {
  height: 400px;
}
.ticketing--dropdown {
  border: 1px solid $black;
  background-color: $sky;
  padding: 8px 5px;
  border-radius: 5px;
}
.ticketing--data {
  @include grid($cols: 1fr, $row-gap: 10px);
  padding-right: 15px;
}
.flex__between a {
  padding: 5px 8px;
  border-radius: 3px;
}
.ticketing-response {
  @include grid($cols: 1fr, $row-gap: 5px);
  label {
    @include grid($cols: 1fr, $row-gap: 3px, $align: center);
    font-weight: bold;
  }
}
.tile-heading {
  padding: 0px !important;
}
.ticketing-messages {
  @include grid($cols: 1fr, $row-gap: 25px);
}
.ticketing-messages--message {
  &:nth-child(even) {
    background: linear-gradient(135deg, $orange, $black);
  }
  &:nth-child(odd) {
    background: linear-gradient(135deg, $green, $black);
  }
  * {
    color: #fff;
  }
  @include grid(
    $rows: 25px 2fr 25px,
    $col-gap: 10px,
    $row-gap: 10px,
    $align: center
  );
  grid-template-areas:
    "name"
    "msg"
    "date";
  .responder {
    grid-area: name;
    font-weight: bold;
  }
  .date {
    grid-area: date;
    text-align: right;
  }
  .response {
    grid-area: msg;
  }
}
@media (min-width: $sm) {
  .ticketing-messages--message {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 25px 2fr;
    grid-template-areas:
      "name date"
      "msg msg";
  }
}
</style>