<template>
  <div class="product-details--status flex__center">
    <p class="text__white text__bold mr-10">{{ status }}</p>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: "Pending"
    }
  }
};
</script>

<style scoped lang="scss">
.product-details--status {
  padding-left: 25px;
  @include pos-abs(
    $pos: absolute,
    $top: -8px,
    $left: null,
    $right: -8px,
    $width: 200px,
    $z: 1
  );
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 14% 100%);
  /* background: linear-gradient(to right, $orange, $black); */
  background-color: $peacock;
}
</style>