<template>
  <section class="margin__md">
    <div class="tile-heading">
      <h2>Order Details</h2>
      <hr />
    </div>
    <div class="confirm-details--tiles">
      <Recipient :active="activeOrder" :canEdit="canEdit" />
      <Contact :active="activeOrder" :canEdit="canEdit" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Contact from "./Contact";
import Recipient from "./Recipient";
export default {
  props: ["activeOrder"],
  components: {
    Contact,
    Recipient
  },
  data() {
    return {
      access: ["admin", "super-admin"]
    };
  },
  computed: {
    ...mapGetters(["getUserDetails"]),
    canEdit() {
      return (
        this.access.includes(this.getUserDetails.permission) ||
        this.activeOrder.status === "Started"
      );
    }
  }
};
</script>