<template>
  <div class="exit-content">
    <p class="text__center">
      You can come back and finish this order later.
      Final details will be saved
      but the order will
      <strong>not</strong> be submitted for fulfillment.
    </p>
    <p class="text__md text__center padding__sm">Proceed?</p>
    <div class="flex__between">
      <button class="btn btn__frost" @click="emitToggleExiting">No, Go Back</button>
      <button class="btn btn__green" @click="emitSubmit">Yes, Exit</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    emitToggleExiting() {
      this.$emit("toggleExiting", false);
    },
    emitSubmit() {
      this.$emit("submit");
    }
  }
};
</script>

<style scoped lang="scss">
p {
  color: #fff;
}
</style>