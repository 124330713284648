<template>
  <section class="margin__lg">
    <section class="container">
      <div class="tile-heading">
        <h2>Delete This Order</h2>
        <hr />
      </div>
      <p class="padding__sm text__md">
        By clicking "Delete" below and confirming your choice, this order and all associated order items will be deleted.
        <br />If this order has been marked as a favorite, it will still be deleted and will not be available as a favorite anymore.
        <br />
        <span class="text__red">You cannot undo this action.</span>
      </p>
      <div v-if="!deleting" class="flex__center">
        <button class="btn btn__lg btn__red" @click="toggleDeleting">
          Delete This Order
          <font-awesome-icon icon="exclamation" size="lg" class="ml-10" />
        </button>
      </div>
      <div v-else class="flex__between">
        <button class="btn btn__lg btn__green" @click="toggleDeleting">No, Go Back</button>
        <button class="btn btn__lg btn__red" @click="deleteOrder">
          Confirm Delete
          <font-awesome-icon icon="trash" size="lg" class="ml-10" />
        </button>
      </div>
    </section>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { createError } from "../../../libs/flash-messages";
export default {
  props: ["active", "user"],
  data() {
    return {
      deleting: false
    };
  },
  computed: {
    hasCompleteOrderItems() {
      return (
        this.active.orderItems.filter(item => item.status === "Completed")
          .length > 0
      );
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startDeleteOrder"]),
    toggleDeleting() {
      this.deleting = !this.deleting;
    },
    async deleteOrder() {
      if (
        this.active.status !== "Started" &&
        this.active.status !== "Submitted"
      )
        return;
      if (this.hasCompleteOrderItems) {
        createError(
          "Can't Delete",
          "This order has products which have already been sent"
        );
        return;
      }
      const form = {
        _id: this.active._id,
        requester: this.user._id,
        requesterName: this.user.displayName,
        requesterEmail: this.user.email,
        requester2Email: this.user.email2
      };
      this.startSetIsSubmitting({ bool: true, heading: "Deleting" });
      try {
        await this.startDeleteOrder(form);
        this.startSetIsSubmitting({ bool: false });
        const isAdmin = ["admin", "super-admin"];
        if (isAdmin.includes(this.user.permission)) {
          return this.$router.push("/admin/past-orders");
        }
        this.$router.push("/");
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    }
  }
};
</script>